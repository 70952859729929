.container {
  display: flex;
  flex-wrap: wrap;

  .detail {
    height: 20rem;
    width: 20rem;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    margin: 1.2rem;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1.6rem;

    .icon {
      border-radius: 50%;
      width: 3.2rem;
      height: 3.2rem;
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.2rem;
    }

    .iconPink {
      background-color: rgb(255, 105, 180, 0.3);
      color: rgb(255, 105, 180, 1);
    }

    .iconDarkGreen {
      background-color: rgb(24, 77, 71, 0.3);
      color: rgb(24, 77, 71, 1);
    }

    .iconBlue {
      background-color: rgb(77, 150, 255, 0.3);
      color: rgb(77, 150, 255, 1);
    }

    .iconRed {
      background-color: rgb(255, 107, 107, 0.3);
      color: rgb(255, 107, 107, 1);
    }

    .iconGreen {
      background-color: rgb(107, 203, 119, 0.3);
      color: rgb(107, 203, 119, 1);
    }

    .iconYellow {
      background-color: rgb(255, 217, 61, 0.3);
      color: rgb(255, 217, 61, 1);
    }

    .label {
      color: #6c757d;
    }
  }
}
