.summary {
  display: flex;
  margin-bottom: 2.4rem;
  flex-wrap: wrap;
  background-color: #fafbfe;
  justify-content: center;

  .paymentDetail {
    margin: 1.2rem;
    padding: 1.6rem;
    width: 20rem;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border-radius: 0.4rem;

    .paymentDetailTitle {
      font-size: 1.6rem;
    }

    .paymentDetailContent {
      font-size: 4rem;
    }
  }
}

.confirmationDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}

.randomWord {
  padding: 0.2rem 2rem;
  background-color: lightgray;
  font-size: 2.4rem;
  color: black;
  font-weight: 600;
  letter-spacing: 1.2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.4rem;
  width: 45%;
}

.confirmationText {
  width: 45%;
}

.confirmationLabel {
  display: block;
  margin-bottom: 0.8rem;
}

.paymentNote {
  margin-bottom: 2.4rem !important;
}
