.button {
  border-radius: 0.4rem !important;
  height: fit-content !important;
}

.primaryButton {
}

.tertiaryButton {
  background-color: #f6f6f6 !important;
  border-color: #f6f6f6 !important;
}
