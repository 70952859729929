.select {
  min-width: 20rem;
  :global {
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }

    .ant-select-selector {
      padding: 0.4rem 1.2rem !important;
      height: fit-content !important;
      border-radius: 0.4rem !important;
    }
  }
}
