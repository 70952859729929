.confirmationDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}

.userInputText {
  width: 45%;
}

.randomWord {
  padding: 0.2rem 2rem;
  background-color: lightgray;
  font-size: 2.4rem;
  color: black;
  font-weight: 600;
  letter-spacing: 1.2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.4rem;
  width: 45%;
}

.confirmationLabel {
  display: block;
  margin-bottom: 0.8rem;
  color: #6c757d !important;
}

.confirmationText {
  margin-bottom: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #6c757d;
}
