.container {
  display: flex;
  align-items: center;

  .profileImage {
    width: 35px;
    height: 35px;
    border-radius: 60px;
    margin: 0 !important;
  }

  .profileDetails {
    margin-left: 0.8rem;
    display: flex;
    flex-direction: column;

    .name {}

    .email {
      opacity: 0.6;
    }
  }
}