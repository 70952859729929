.inboxBox {
  position: relative;
  padding: 16px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  margin-top: 8px;
  background-color: white;
}

.inboxBox:hover {
  background-color: #f1f1f1;
}

.selectedInboxBox {
  background-color: #e1e1e1;
  // position: absolute;
}

.userBox {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 16px;
}

.groupChat {
  text-transform: capitalize;
}

.username {
  font-weight: 500;
}

.userImage {
  width: 30px;
  height: 30px;
  border-radius: 60px;
}

.showTime {
  display: flex;
  font-size: 10px;
  color: #A7A7A7;
  justify-content: center;
}

.left {
  height: 600px;
  overflow: auto;
}

.right {
  height: 520px;
  overflow: auto;
}