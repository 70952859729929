.autocompleteDropdownContainer {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.autocompleteDropdownContainer:empty {
  display: none;
}
