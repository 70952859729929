.spinContainer {
  // position: absolute;
  // z-index: 10;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);

  .spin {
    color: #444;

    i {
      background-color: #444;
    }
  }
}
