.sales {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 0.4rem;

  :global {
    .ant-table-tbody > tr {
      cursor: auto !important;
    }
  }
}

.noresults {
  width: 100%;
  display: flex;
  justify-content: center;

  .message {
    display: block;
    font-size: 2rem !important;
    font-weight: 500;
    color: #6c757d !important;
  }
}

.icon {
  padding: 0rem !important;
  cursor: pointer;
}

.icon + .icon {
  margin-left: 0.4rem;
}
