.saveButton {
  width: 100%;
  padding: 0.8rem;
}

.input {
  padding: 0.8rem !important;
  border-radius: 0.4rem !important;
}

.select {
  :global(.ant-select-selector) {
    padding: 0.4rem 1.2rem !important;
    height: fit-content !important;
    border-radius: 0.4rem !important;
  }
}