.pageContainer {
  height: 100%;
}

.pageTitle {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.profileImageContainer {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  .profileImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.6rem;
  }

  .profileName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    align-items: center;

    .username::before {
      content: "@";
    }
  }
}

.divider {
  text-align: center;
}

.pageContent {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  .actions {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 1rem;
  }

  .rolesSection {
    background-color: white;
    padding: 0.5rem 0rem;

    :global(.ant-tabs-tabpane) {
      padding-right: 1.5rem;
    }

    .module {
      padding: 1rem 0rem;
    }

    .moduleName {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .permissions {
      display: flex;
      flex-wrap: wrap;

      .tag {
        padding: 0.25rem 0.5rem;
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }

    .module + .module {
      border-top: 1px solid lightgray;
    }
  }
}
