.inputContainer {
  // .input {
  //   border-radius: 0.4rem;
  //   padding: 0.8rem 1.2rem;
  //   box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  //   width: 100%;
  // }

  .inputNumber {
    width: 100%;
  }

  .errorInput {
    border-color: #ff4d4f !important;
  }

  .warningInput {
    border-color: #faad14;
  }

  .successInput {
    border-color: #52c41a;
  }

}