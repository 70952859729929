.time {
  font-size: 10px;
  // fontFamily: family.semiboldRajdhani;
  color: #fff;
  font-weight: 500;
  margin-left: 8px;
}

.showMore {
  font-size: 12px;
  // fontFamily: family.semiboldRajdhani;
  color: #E35F2C;
  margin-left: 8px;
}

.itemLeft {
  padding-top: 4px;
  padding-bottom: 4px;
  align-self: flex-start;
  flex-direction: "row";
  display: flex;
}

.otherTextMessage {
  background: #f6ccbb;
}

.deleteTextMessage {
  background: #dadada;
  padding-bottom: 0px !important;
  // height: 33px;
}

.msgtxtDelete {
  font-size: 12px;
  color: #000;
}


.changeLine {
  display: flex;
  flex-direction: column;
}

.groupSpace {
  margin-left: 38px;
}

.commonTextMessage {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  max-width: '85%';
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;

  p {
    margin: 0px !important;
  }
}

.timeNshowmore {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.msgUsername {
  // fontFamily: family.regularRajdhani;
  font-size: 12px;
  color: #E35F2C;
}

.msgtxt {
  // fontFamily: family.regularRajdhani;
  font-size: 13px;
  color: #242424;
}