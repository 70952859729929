.pageContainer {
  height: fit-content;
}

.pageTitle {
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
}

.pageContent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 0.4rem;
  max-height: calc(100vh - 240px);
  overflow: hidden;

  .actions {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 1.2rem;
  }

  .rolesSection {
    background-color: white;
    padding: 0.4rem 0rem;
    height: 100%;
    overflow: auto;

    :global(.ant-tabs) {
      height: 64.5rem;
      overflow: hidden;
    }

    :global(.ant-tabs-tabpane) {
      padding-right: 1.6rem;
    }

    :global(.ant-tabs-nav-list) {
      min-width: 20rem;
    }

    .module {
      padding: 1.2rem 0rem;
    }

    .moduleName {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 0.8rem;
      color: #313a46;
    }

    .permissions {
      display: flex;
      flex-wrap: wrap;

      .tag {
        padding: 0.4rem 1.2rem;
        border-radius: 0.4rem;
        cursor: pointer;
        color: #6c757d;
        margin-right: 1.2rem;
      }
    }

    .module + .module {
      border-top: 1px solid lightgray;
    }
  }
}
