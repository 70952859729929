.pageContainer {
  margin-top: 64px;
}

.numbText {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2;
  color: #ff6304;
}

.profitNumber {
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.2;
  color: #ff6304;
}

strong {
  color: #ff6304;
}

.icon {
  border-radius: 100px;
  width: 45px;
  height: 45px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconImage {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.chartBoxUser {
  margin-top: -75px;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  display: flex;
  overflow: hidden;
}

.chartBox {
  margin-top: -25px;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  display: flex;
  overflow: hidden;
}

.divider {
  border-left: 1px solid gray;
  width: 10%;
}

.p_3 {
  padding: 24px;
  padding-bottom: 0px !important;
}

.selectionBoxLeft {
  display: flex;
  width: 60%;
}

.selectionBox {
  display: flex;
  flex-direction: column;
  width: 20%;

  .text1 {
    margin-top: 24px;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    color: #999;
  }

  .text2 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #ff6304;
    text-align: center;
  }
}

.topImg {
  width: 35px;
  height: 35px;
  border-radius: 70px;
  border: 0.3px solid #ccc
}

.postContainer {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  margin-top: 16px;

  .postImgSize {
    width: 20%;
    // margin-right: 8px;
    margin-bottom: 24px;
  }

  .postImgBox {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    transition: 0.3s;
  }

  .postImg {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    transition: 0.3s;
  }

  .postImg:hover {
    transition: transform .2s;
    transform: scale(1.2);
  }

  .postText {
    visibility: hidden;
    transition: visibility 1s ease-in-out;
  }

  .postImg:hover+.postText {
    transition: visibility 1s ease-in-out;
    visibility: visible;
    position: absolute;
    margin-top: 60px;
    width: 100%;
    border-radius: 4px;
    font-size: 12px;
    z-index: 9999;
    background-color: #ff6304;
    text-align: center;
  }


}

.topLabel {
  font-size: 1.6rem;
  font-weight: 400;
  color: #000;
}

.topLabel:hover {
  font-weight: 500;
}

.topCount {
  font-size: 1.6rem;
  font-weight: 400;
  color: #000;
  text-align: center;
}

.topCount:hover {
  font-weight: 500;
}


.card {
  // padding: 24px;
  // margin-right: 3rem !important;
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.8rem;
}