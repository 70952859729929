.pageContainer {
  height: 100%;
}

.pageTitle {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.pageContent {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  .actions {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 1rem;
  }
}

.icon {
  padding: 0rem 0.4rem !important;

  &.iconDanger {
    color: red;
  }
}