.heading {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 0.4rem;
  display: flex;
  margin-bottom: 2.4rem;

  .paymentDetails {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 24rem;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
      border-radius: 0.4rem;
      padding: 2rem;

      .detailLoader {
        margin-bottom: 2rem;
      }

      .date {
        font-size: 1.6rem !important;
      }
    }
  }

  .paymentDetails + .paymentDetails {
    margin-top: 2.4rem;
  }

  .note {
    margin-top: 2rem;
    .noteTitle {
      font-size: 1.6rem !important;
      font-weight: 500 !important;
    }

    .noteContent {
      display: block;
      color: #6c757d;
    }
  }
}
