.container {
  background-color: #fafbfe;
}

.boxcontainer {
  // width: calc(100vw - (100vw - 100%) - calc(2rem * 8));
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2) );
  margin: 16px 24px;
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(51, 55, 59, 0.1);
  padding: 0.8rem 2rem;
  border-radius: 0.428rem;

  .userDetail {
    align-items: center;
    margin-right: 8px;
    margin-left: 8px;

    .fullname {
      font-size: 1.2em;
      font-weight: 600;
      text-align: right;
    }

    .username {
      font-size: 0.9em;
      font-weight: 400;
      margin-top: -6px;
      text-align: right;
    }

  }
}