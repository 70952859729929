.adminPromtionOnly {
    display: flex;
    align-items: center;
    position: absolute;
    right: 82px;
    top: 32px;
}

.icon {
    padding: 0rem 0.4rem !important;

    &.iconDanger {
        color: red;
    }
}