.label {
  font-weight: 500;
  color: #374151;
  font-size: 1.4rem;
}

.title {
  font-size: 3rem !important;
  font-weight: 800 !important;
  color: #374151 !important;
  margin-bottom: 0rem !important;
}
