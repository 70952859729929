.container {
  padding: 3.2rem 4rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.input {
  margin-top: 2.4rem;
}

.button {
  margin-top: 2.4rem;
  width: 100%;
  padding: 0.8rem 1.2rem !important;
}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;
  align-items: center;

  .forgotPassword {
    padding: 0rem !important;
  }
}