.container {
  display: flex;
  flex-direction: column;

  .profileName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    align-items: center;

    .username::before {
      content: "@";
    }
  }

  .profileDetails {
    display: flex;
    flex-direction: column;

    .detail {
      .detailTitle {
        font-size: 1.6rem !important;
      }
    }

    .detail + .detail {
      margin-top: 1.2rem;
    }
  }
}

:global(.ant-select-selector) {
  padding: 0.4rem 1.2rem;
  height: fit-content;
  border-radius: 0.4rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}
