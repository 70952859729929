.container {
  :global {
    .ant-table-tbody > tr > td {
      padding: 1.2rem;
      color: #6c757d;
    }

    .ant-table-thead > tr > th {
      padding: 1.2rem;
      background: #eef2f7;
      color: #313a46;
    }

    .ant-table-tbody > tr {
      cursor: pointer;
    }
  }
}
