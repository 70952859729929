.pageContainer {
  height: 100%;
}

.card {
  margin: .5rem;
}

.cardImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.likes {
  left: 8px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  margin-top: -50px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  font-size: 12px;
}

.comments {
  position: absolute;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  margin-top: -50px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  font-size: 12px;
}

.freelancerRole {
  color: #FFF;
  position: absolute;
  right: 8px;
  background-color: rgba(255, 106, 0, 0.5);
  top: 8px;
  z-index: 999;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  font-size: 12px;
}

.userRole {
  color: #FFF;
  position: absolute;
  right: 8px;
  background-color: rgba(57, 80, 255, 0.5);
  top: 8px;
  z-index: 999;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  font-size: 12px;
}

.statusReported {
  position: absolute;
  right: 8px;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 4px;
  background-color: red;
  color: #FFF;
  font-size: 12px;
}

.statusDeleted {
  position: absolute;
  right: 8px;
  padding-right: 4px;
  padding-left: 4px;
  background-color: #ff6304;
  border-radius: 4px;
  color: #FFF;
  font-size: 12px;
}



.showMoreButton {
  margin: 24px;
}