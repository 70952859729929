.container {
  display: flex;
  align-items: center;

  .icon {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin-right: 0.4rem;
  }
}

.deleted {
  background-color: red;
}

.active {
  background-color: green;
}

.inactive {
  background-color: gray;
}
