.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f9fafb;
}

.content {
  height: 100%;
  width: 100%;
  max-width: 44.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  height: 4.8rem;
  margin-bottom: 2.4rem;
}

.title {
  margin-bottom: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleText {
  text-align: center;
}
