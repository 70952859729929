.container {
  .module {
    padding: 1.2rem 0rem;
    max-width: 40rem;
  }

  .moduleName {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
    color: #313a46;
  }

  .permissionsContainer {
    margin-top: 2.4rem;
  }

  .permissions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    :global(.ant-tag-checkable-checked) {
      background-color: #87d068;
    }

    .tag {
      padding: 0.4rem 1.2rem;
      border-radius: 0.4rem;
      cursor: pointer;
      color: #6c757d;
      border: 1px solid #6c757d;
      width: 8rem;
      text-align: center;
    }

    .active {
      color: white;
      border: 1px solid #87d068;
    }
  }

  .module + .module {
    border-top: 1px solid lightgray;
  }
}
