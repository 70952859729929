.container {
  display: flex;
  flex-direction: column;

  .loginMethod {
    display: flex;
    position: absolute;
  }

  .genderType {
    right: 24px;
    display: flex;
    position: absolute;
  }

  .profileImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.6rem;
  }

  .profileName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    align-items: center;

    .username::before {
      content: "@";
    }
  }

  .profileDetails {
    display: flex;
    flex-direction: column;

    .detail {
      .detailTitle {
        font-size: 1.6rem !important;
      }
    }

    .detail+.detail {
      margin-top: 1.2rem;
    }
  }
}