.content {
  height: 100%;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
  background-color: white;
  border-radius: 0.4rem;
  padding: 1.6rem 2.4rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.note {
  display: flex;
  align-items: center;

  .infoIcon {
    font-size: 2rem;
    color: #6c757d;
  }

  .noteContent {
    margin-left: 1.6rem;
    font-weight: 500;
    font-size: 1.6rem;
    color: #6c757d;
  }
}
