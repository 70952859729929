.paymentStatus {
    display: flex;
    align-items: center;
    position: absolute;
    right: 80px;
    top: 28px;
}

.SwitchText {
    margin-left: 16px;
    margin-right: 16px;
    font-size: 1.5rem;
    font-weight: 400;
    color: #5e5873ac;
}

.icon {
    padding: 0rem 0.4rem !important;

    &.iconDanger {
        color: red;
    }
}